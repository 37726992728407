.Wrapper {
  width: 100%;
  text-align: center;
  cursor: pointer;

  transition: all 0.5s ease;

  figure {
    transition: all 1s ease;
    height: 155px;

    img {
      width: 80%;
      height: 100%;
      margin: auto;
    }
  }

  h4 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    transition: all 1s ease;
  }

  // &:hover {
  //   transition: all 0.5s ease;
  //   transform: scale(0.9);

  //   figure {
  //     transform: translate(0, 40px);
  //     transition: all 1s ease;
  //   }

  //   h4 {
  //     color: #01857a;
  //     transition: all 0.3s ease;
  //     transform: translate(0, -190px);
  //     font-size: 1.3rem;
  //   }
  // }
}