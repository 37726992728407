.TableWrapper {
  .Title {
    color: #3232328c;
  }

  .Title,
  .Content {
    font-size: 0.8rem;
  }
}

.ChipBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;

  .Chip {
    height: 22px;
    font-size: 0.7rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.DebTitle {
  font-size: .9rem !important;
  font-weight: bold;
  margin-bottom: 0px !important;
}