.Wrapper {
  text-align: center;
  
  .BoxImage {
    display: flex;
    padding: 2rem;

    img {
      margin: auto;
      width: 20%;
    }
  }
}
