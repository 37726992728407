.MortgageCardWrapper {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  border: 1px solid #e3e3e3;
  border-radius: 10px;
  // margin-top: 1rem;

  .FootControls {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }

  .Header {
    width: 100%;

    .Top {
      padding: 1rem 0.5rem 2rem 0.5rem;

      display: block;
      margin-bottom: 1.5rem;

      .Chip {
        background-color: #01857a;
        color: rgba(255, 255, 255, 0.87);
        height: 1.3rem;

        .Icon {
          width: 1rem;
          color: aliceblue;
        }
      }

      .ChipPending {
        background-color: #dcdcdc;
        color: rgba(255, 255, 255, 0.87);
        height: 1.3rem;

        .Icon {
          width: 1rem;
          color: aliceblue;
        }
      }

      .Live {
        float: right;
        right: 10px;
        position: relative;
        font-size: 0.8rem;

        .Icon {
          width: 13px;
          height: 13px;
          border: 1px solid #01857a;
          position: absolute;
          left: -20px;
          top: 0px;
          border-radius: 50%;

          &::before {
            content: " ";
            width: 7px;
            height: 7px;
            background-color: #01857a;
            position: absolute;
            border-radius: 50%;
            left: 3px;
            top: 3px;
            animation: deelay 1s infinite;
          }
        }
      }

      .LivePending {
        float: right;
        right: 10px;
        position: relative;
        font-size: 0.8rem;

        .Icon {
          width: 13px;
          height: 13px;
          border: 1px solid #dcdcdc;
          position: absolute;
          left: -20px;
          top: 0px;
          border-radius: 50%;

          &::before {
            content: " ";
            width: 7px;
            height: 7px;
            background-color: #dcdcdc;
            position: absolute;
            border-radius: 50%;
            left: 3px;
            top: 3px;
          }
        }
      }
    }

    .Image {
      display: flex;
      margin-bottom: 2rem;

      img {
        width: 40%;
        margin: auto;
      }
    }
  }

  .Content {
    padding: 0.5rem;
    color: #3e3e3e;

    .Left,
    .Right {
      display: inline-block;
    }

    .Left {
      width: 80%;

      span {
        display: block;
        margin-bottom: 0.3rem;
      }

      .Message {
        font-size: 0.9rem;
      }
    }

    .Right {
      width: 100%;
      position: relative;

      .Controls {
        position: absolute;
        right: 0;
        top: -40px;
      }
      .controlsCreated {
        position: static;
        text-align: end;
      }
    }
  }

  .title_modal_cancel_mortagage {
    font-size: 20px;
  }

  .Footer {
    width: 100%;
    background-color: #01857a;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .Left,
    .Right {
      padding: 0.5rem;
      display: inline-block;
      width: 45%;

      .Title {
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .Left {
      text-align: left;

      .Countdown {
        display: block;
        color: #fff;

        font-style: normal;

        font-size: 16px;
        line-height: 24px;

        letter-spacing: 0.15px;
      }
      .Date {
        font-size: 0.8rem;
      }
    }

    .Right {
      text-align: right;

      .Percent {
        display: block;
        color: #fff;
      }
    }
  }
}

.links {
  color: #01857a !important;
  cursor: pointer;
}
.links:hover {
  font-weight: 900;
}

@keyframes deelay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
