@import "themes/scss/media-queries.scss";

.CalculationsWrapper {
  display: flex;
  margin: 1rem 0rem;

  background-color: #5eada614;
  padding: 1rem;
  border: 1px solid #d7ebe8;
  border-radius: 5px;

  @include for-phone-portrait {
    flex-direction: column;
  }

  .CalculationsResults {
    margin: 0;
    padding: 0rem;
    width: 100%;
    position: relative;

    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: center;
    color: #01857a;

    // .MortgageRate {
    //   color: blue;
    // }

    .WarningAlert {
      color: #db0a0a;
    }

    @include for-phone-portrait {
      margin-bottom: 0.5rem;
    }

    &:not(:last-child):after {
      content: " ";
      background-color: #d3d3d3;
      height: 50%;
      width: 1px;
      position: absolute;
      right: 0;
      top: 0;

      @include for-phone-portrait {
        display: none;
      }
    }

    h6 {
      font-size: 1.3rem;
      margin: 0;

      @include for-phone-portrait {
        font-size: 1rem;
      }
    }

    .labelContainer {
      padding-inline: 1rem;


      small {
        font-size: 1rem;
        font-weight: 500;

        text-overflow: ellipsis;
        // white-space: nowrap;
        overflow: hidden;

      }

      p {
        font-size: 0.8rem;
        margin: 0;
        // font-weight: 500;

        text-overflow: ellipsis;
        // white-space: nowrap;
        overflow: hidden;

      }

      svg {
        font-size: 0.9rem;
      }
    }
  }
}