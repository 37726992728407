.HeaderBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .HeaderItem {
    display: flex;
    flex-direction: column;
  }
}

.ListGroup {
    margin-top: .8rem;
  .ListItem {
    padding-left: 0;
    padding-right: 0;

    .ListIcon {
      color: #28938a;
    }
  }

  .SubTitle {
    padding-left: 0;
    font-weight: 100;
    background-color: transparent;
  }
}
