.UploadFilesZone {
  background-color: #00bfa60f;
  border: 2px dashed #dddcdc;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  flex: 1;
  color: #a49e9e;
  font-size: 0.8rem;
  transition: all 0.5s ease;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  min-height: 100px;
  min-width: 100px;

  img {
    display: block;
    margin: auto;
  }

  p {
    margin-top: 0.7rem;
    margin-inline: 1rem;
  }

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 1;
    cursor: not-allowed;

    & > .imgs >img {
      filter: blur(2px)
    }
  }

  div {
    margin: auto;
  }

  & > .imgs {
    position: relative;
    &> * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}



.UploadFilesContent {
  margin: 3rem;
  margin-top: -1.5rem;

  input,
  select {

    border-radius: 5px;
    border: 1px solid #afafaf78;
    padding: 0.5rem;
    width: 100%;
    color: #244765;
  }

  .Item {
    display: flex;
    border-bottom: 1px solid #e8e8e8a3;
    padding-bottom: 0.8rem;

    .Icon {
      font-size: 2rem;
      font-weight: 100;
      color: #e62679;
      margin-right: 1rem;
      margin-top: -1px;
    }

    .Name {
      margin-right: 0.7rem;
      width: 100%;
    }

    .Type {
      width: 100%;
    }

    .Spinner {
      margin-left: 1rem;

      i {
        margin-top: 0.3rem;
        color: #01c1f4;
        font-size: 1.4rem;
      }
    }
  }
}