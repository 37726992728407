.DialogDetails {
  padding: 1.5rem;

  .Title,
  .Content {
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 0.5rem;
  }
}

.BonusList {
  background-color: #fbfbfb;
  padding: 1.7rem;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  margin-bottom: 0.5rem;

  .CheckBox {
    padding: 0;
  }
}

.LabelCheckbox {
  font-size: 0.9rem !important;
}

.TableBalance {
  td {
    padding: 0.7rem 0;
    width: 50%;

    span {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }

  tr {
    &:last-child {
      td {
        border: none;
      }
    }
  }
}

.Hightlight {
  color: #01857a;
}

.WarningAlert {
  color: #db0a0a !important;
}
.toolTip {
  width: 1rem;
}
.toolTip {
  width: 1rem;
}
.toolTip {
  color: #33cccc;
  margin: 0 0.5rem;
}
