// .MenuIcon {
//   // position: absolute !important;
// }

.MenuIconHidden {
  visibility: hidden;
}

.Logo {
  width: 100%;
  text-align: end;

  img {
    width: 8rem;
  }
}

.MainTopBar {
  position: relative;

  .ProgressBar {
    border-bottom: 5px solid #01857a;
    content: " ";
    width: 0px;
  }
}

.Banner {
  margin: auto;

  img {
    margin-top: 1px;
    margin-right: -20px;
    width: 5rem;
  }

  @media only screen and (max-width: 600px) {
    img {
      margin-right: -14px;
    }
  }
}
