.CountdownIcon {
  display: inline-block !important;
  margin-top: -3px;
  margin-right: 0.5rem;
}

.Countdown {
  display: inline-block !important;
  font-family: Arial;
  color: #01857a;
}

.CountdownWrapper {
  display: flex;
  justify-content: flex-end;
  color: #01857a;
}
.TableLogo {
  box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.DialogDetails {
  padding: 1.5rem;

  .Title {
    color: #3232328c;
  }

  .Title,
  .Content {
    font-size: 0.8rem;
  }
}

.TableDetails {
  .Title {
    color: #3232328c;
  }

  .Title,
  .Content {
    font-size: 0.8rem;
  }
}

.DebTitle {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin-bottom: 0px !important;
}
div.ButtonsWrapper-sc-__sc-1ckh2u2-2.gQAyhH {
  display: none;
}
