.ClientCardWrapper {
  transition: all 0.5s ease;
  border: 1px solid 80808000;

  &:hover {
    color: #01857a;
    transition: all 0.5s ease;
    cursor: pointer;
  }

  .HeaderBox {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    .HeaderItem {
      display: flex;
      flex-direction: column;
    }
  }

  .ClientDetails {
    .Title {
      color: #3232328c;
    }

    .Title,
    .Content {
      font-size: 0.8rem;
    }

    .ContentIcons {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .BoxClientFlags {
    text-align: center;
    display: flex;
    width: 97%;
    flex-direction: row-reverse;
    height: 1rem;
    margin-top: 0.5rem;

    .GoldPaidIcon {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      color: rgb(201, 167, 38);
      font-size: 2rem;
    }
  }
}
