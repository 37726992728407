.ShareTools,
.ProfileBox {
  display: flex;
}

.CodeBox {
  margin-bottom: 0.8rem;
  width: 100%;
}

.ShareBox {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
}

.WhiteButton {
  background-color: #fff !important;
  color: #505050 !important;
  margin: 0.3rem !important;

  position: absolute !important;
  right: 0;
  top: -30px;
}

.WhatsappButton {
  background-color: #25d366 !important;

  color: #fff !important;
  margin: 0.3rem !important;
}

.FacebookButton {
  background-color: #3b5998 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.LinkedinButton {
  background-color: #0082d3 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.TelegramButton {
  background-color: #4fa9e1 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}
.Instagram {
  color: #fff !important;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.MailButton {
  background-color: #01857a !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.TwitterButton {
  background-color: #1da1f2 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.ProfileBox {
  padding: 1rem;

  .AvatarBox {
    margin: auto;
    width: 50%;
    text-align: center;
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      height: 113%;
      width: 1px;
      background-color: #dddddd;
      right: 0;
      top: 0;
    }
  }
}

.MetricsBox {
  width: 50%;
  text-align: center;

  .Item {
    margin: 1rem;
    color: #7a7a7a;

    .Number {
      color: #01857a;
    }
  }
}

.iFrameBox {
  position: relative;
  padding: 0.5rem;
  border: 1px solid #bdbdbd;
}

.DialogDetails {
  padding: 1.5rem;

  .Title,
  .Content {
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 0.5rem;
  }
}

.InputFields {
  margin-bottom: 0.9rem !important;
}

.DownloadButton {
  background-color: #fff !important;
  color: #505050 !important;
  margin: 0.3rem !important;

  position: absolute !important;
  right: 0;
  top: 1.5rem;
}

@media only screen and (max-width: 480px) {
  .ReferralsWrapper {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
