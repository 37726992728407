.stepLabels {
}
.StepTitle {
  font-weight: 600 !important;
  color: #6a7272;
  position: relative;
  padding: 0 1rem;
  text-align: center;
  display: block;
}
// .StepTitle::after {
//   font-family: "Material Icons";
//   content: "\e88e";
//   width: 0.9rem !important;
// }

.StepCaption {
  display: block;
  font-weight: 100 !important;
}

.StepTimelineTitle {
  display: block;
  font-weight: 500 !important;
  color: #626262;
  font-size: 1rem !important;
}

.StepTimelineCaption {
  display: block;
  font-weight: 400 !important;
  color: #6a7272;
  font-size: 0.8rem !important;
}
.label {
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
}

.labelMobile {
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  font-size: 0.9rem;

  span {
    display: block;
    font-size: 0.7rem;
  }
}

.containerIcon {
  text-align: center;
  display: block;
}

.infoIcon {
  position: relative;
  width: 1rem !important;

  margin-bottom: -5px;
  display: block;
  color: black;
  text-align: center;
}

.AccordionTitle {
  color: #01857a;
  width: 70%;
  flex-shrink: 0;
  font-size: 0.8rem !important;
}

.AccordionCaption {
  color: #636363;
  font-size: 0.8rem !important;
}

.AccordionIconTitle {
  float: left;
  margin-top: -2px;
}

.AccordionContent {
  font-size: 0.9rem !important;
}

@media only screen and (max-width: 600px) {
  .StepTitle {
    display: inline-block;
    padding: 4px;
    box-sizing: border-box;
    margin-top: 4px;
  }
  .containerIcon {
    display: inline-block;
    height: auto;
  }

  .stepLabels {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-device-width: 768px) {
  .StepTitle {
    text-align: inherit;
  }
}
