.error {
  color: #d32f2f !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.fontFamily {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 1px;
}
.fontFamily p {
  padding: 0 1rem;
}
.numberText p {
  font-size: 11px;
}
.paragraph {
  color: #4b4b4b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.5px;
  max-width: 780px;
  margin: 0 auto;
}
.hightlight {
  font-weight: bold;
  color: #01807a;
}
.bold {
  font-weight: bold;
}

.hightlightOpen {
  font-weight: bold;
  color: #01807a;
  cursor: pointer;
}
.customFont {
}

.links {
  color: #01857a !important;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.links:hover {
  font-size: 1.05rem;
}

.fontFamilyTitleNumber {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

.boxStyle {
  margin-top: 3rem;
  background-color: #f5f5f5;
  font-family: "Roboto";
  font-weight: 400;
  box-shadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)";
}
