.ag-theme-alpine {
  --ag-header-height: 30px;
  --ag-header-foreground-color: white;
  --ag-header-background-color: #01857a;
  /* --ag-foreground-color: #01857a; */
  /* --ag-data-color: black  */

  /* --ag-header-cell-hover-background-color: rgb(80, 40, 140);
  --ag-header-cell-moving-background-color: rgb(80, 40, 140); */
}

/* .ag-header-active{
  color: white
}

.ag-theme-alpine .ag-popup{
  color: white

} */
/* .ag-theme-alpine .ag-header {
  font-family: cursive;
} */

 /* .ag-theme-alpine .ag-header-group-cell {
  color: white;
  font-weight: normal;
  font-size: 22px;
}

.ag-theme-alpine .ag-header-cell {
  font-size: 18px;
}  */

span.CrmHeaderBadge {
  background-color: #29b6f6;
  font-weight: bolder;
  border-radius: 0.4rem;
  padding: 0.3rem 0.5rem;
  margin-left: 0.6rem;
}