#root {
  height: 100vh;
}

/** MUI Drawer custom styles */
.MuiDrawer-root {
  .MuiButtonBase-root {
    &:hover {
      background-color: #03dac517;
    }

    .MuiListItemText-root {
      .MuiTypography-root {
        font-size: 0.9rem;
      }
    }
  }

  .MuiPaper-root {
    min-width: 16rem;
    padding-top: 3rem;

    .MuiPersonalInfo-root {
      padding: 1rem;

      h6 {
        margin-top: 0.5rem;
        margin-bottom: -5px;
      }

      span {
        color: #9d9d9d;
      }
    }
  }
}

.bodered_error {
  border: 1px solid #d32f2f !important;
  color: #d32f2f !important;
}

.MuiTableRow-hover:hover,
.MuiListItem-root:hover {
  background-color: #03dac517 !important;
}

.capitalize {
  text-transform: capitalize;
}

.asterisk-label {
  &::after {
    content: "*";
  }
}

.censure {
  filter: blur(6px);
  user-select: none;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;

  -moz-user-select: none;
  -ms-user-select: none;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.swal2-container {
  z-index: 99999999 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  .swal2-title {
    padding-bottom: 1rem;
    font-weight: 300;
  }

  .swal2-html-container {
    padding: 0 1.5rem;
    font-size: 0.8rem;
    margin: 5px;

    p {
      font-size: large;
    }
  }

  .swal2-actions {
    width: 100%;
    gap: 2rem
  }

  .swal2-confirm {
    background-color: #01857a !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 16px !important;
    border-radius: 4px !important;

    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    &:hover {
      background-color: rgb(0, 93, 85) !important;
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
      background-image: none !important;
    }

    &:focus {
      box-shadow: 0 0 0 3px rgb(1 133 122 / 18%);
    }
  }

  .swal2-cancel {
    background-color: #CDCDCD;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #01857a;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 16px !important;
    border-radius: 4px !important;

    &:hover {
      background-color: #CDCDCD70;
      background-image: none !important;
    }
  }
}

.title_modal_cancel_mortagage {
  font-size: 1.5em !important;
}

.Toastify {
  .Toastify__toast-container {
    z-index: 999999999;
  }
}

.MuiTooltip-tooltip {
  background-color: #eefcfd !important;
  border: 1px solid #c0dbdd !important;
  color: #000 !important;

  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  &:before {
    background-color: #90c0c3 !important;
  }

  p {
    font-size: 0.8rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 100 !important;
  }
}

.MuiTooltip-arrow {
  color: #90c0c3 !important;
}

.whatsapp-icon-lib-left {
  height: unset;

  > {
    left: 2rem !important;
  }
}

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}

.MuiStep-vertical {
  text-align: left !important;
}

.swal_alert {
  padding: 2rem;
}

mark {
  background-color: #D9F6EF;
}

.bg-yellow {
  background-color: #f2f9bf;
}

.bg-purple {
  background-color: #e2e2f8;
}

.height-unset {
  height: unset !important;
}

.colibid-color {
  color: #01857a;
}

.colibid-accent-color {
  color: #33CCCC;
}