.ChildWrapper {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;

  & > span,
  div,
  button {
    margin-right: .5rem;
  }
}
