.HomeWrapper {
  height: 100vh; /* Establece la altura al 100% del alto de la ventana del navegador */
}
.fullHeight {
  height: 70%;
}

.HomeWrapper {
  .HeaderBox {
    text-align: center;
    margin-top: 1rem;
  }
}

.cellHiglights {
  font-weight: bold !important;
  color: black;
  font-size: 16px;
}
.customCell {
  border: "1px solid black";
}
.colorScoringHightValue {
  color: #a1cc47;
}
.colorScoringLowValue {
  color: #fdcd00;
}

// $disabled-color: rgba(0, 0, 0, 0.38);
$disabled-color: #f2f2f2;
$colibid-color: #01857a;

.chooseBidBtn {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.customTable {
  display: block;
  overflow: auto;
  width: 100%;
  border-collapse: collapse;
  zoom: 80%;
  font-weight: bold;
  font-size: large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  tr {
    th,
    td {
      text-align: center;
      padding: 0.5rem;
      border-bottom: 0.5px solid $disabled-color;
      border-right: 0.5px solid $disabled-color;
    }

    th {
      text-align: center;
      color: $colibid-color;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg.deactive {
          visibility: hidden;
        }
      }
    }

    td {
      @media screen and (min-width: 600px) {
        font-size: calc(10px + (18 - 10) * ((100vw - 600px) / (1600 - 300)));
      }
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }
  }
}

.columnEditable {
  cursor: pointer;
}

.stickyCell {
  position: sticky;
  left: 0;
  background-color: white; /* Adjust as needed */
  z-index: 1;
}
