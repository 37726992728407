.Box {
  text-align: center;
  margin-top: 6rem;

  img {
    width: 60%;
    margin: auto;
  }
}

.InputsBox {
  margin-top: 3rem;
}

.TitlesBox {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.FormButton {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Labels {
  font-size: 0.9rem;
}

.AccordionTitle {
  color: #01857a;
  width: 70%;
  flex-shrink: 0;
  font-size: 0.8rem !important;
}

.AccordionCaption {
  color: #636363;
  font-size: 0.8rem !important;
}

.AccordionIconTitle {
  float: left;
  margin-top: -2px;
}

.AccordionContent {
  font-size: 0.9rem !important;
}