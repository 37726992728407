.HeaderBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .HeaderItem {
    display: flex;
    flex-direction: column;
  }
}

.ListGroup {
  margin-top: 0.8rem;
  .ListItem {
    padding-left: 0;
    padding-right: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;

    .ListIcon {
      color: #28938a;
    }
  }

  .SubTitle {
    padding-left: 0;
    font-weight: 100;
    background-color: transparent;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: #000000;
  }
}
.listItemText:nth-child(2) {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.15px;

  color: #4b4b4b;
}
.listItemText {
  width: 100%;
}
.ListIcon {
  color: #28938a;
}
