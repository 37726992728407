.myBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  font-family: "Roboto";
}

.myBoxNumber {
  width: 100%;
  border-radius: 4px;
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.3px;
  font-family: "Roboto";
}

.myBoxTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* identical to box height, or 167% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.6px;

  color: #000000;
}

.myBoxParagraph {
  font-family: "Roboto";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
