@import "../../../themes/scss/media-queries.scss";

.HeroButtons {
  display: inline-flex;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;

  .HeroButton {
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    padding: 1rem 1rem;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem auto;
    width: 25%;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.5s ease;

    @include for-phone-portrait {
      flex-direction: column;
      width: 100%;
    }
    div {
      flex-grow: 1;
    }

    img {
      width: 50%;
      aspect-ratio: 1 / 1;
      margin: auto;
    }

    .CustomTitle {
      font-size: 1rem;
      margin-top: 1rem;
      display: block;
    }

    .CustomCaption {
      display: block;
      font-size: 0.9rem;
      text-align: center;
    }

    span {
      width: 100%;
      margin: 0.5rem 0px;
      font-size: 0.8rem;
      transition: all 0.5s ease;
    }

    &:hover {
      border: 1px solid #4fa69f;
      transition: all 0.5s ease;

      span {
        color: #01857a;
        transition: all 0.5s ease;
      }
    }
  }
}
