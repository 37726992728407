.DialogDetails {
  padding: 1.5rem;
  text-align: center;

  .Title {
    color: #3232328c;
  }

  .Title,
  .Content {
    font-size: 0.8rem;
  }
}

.AlertInfo {
  text-align: left;
  font-size: 0.8rem;
}

.links {
  color: #01857A !important;
  cursor: pointer;
}

.links:hover {
  font-weight: 900;
}
