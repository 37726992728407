.ChipAuto {
  border-radius: 5px !important;
  padding-top: 0.1rem !important;
  text-transform: uppercase !important;
  font-size: 0.7rem !important;
  height: 1.3rem !important;
  margin-left: 0.5rem !important;
}

.MainCaption {
  margin-top: 0.5rem !important;
  border: 1px solid #bcdfee !important;
  color: #2b6f8c !important;
  font-weight: 300 !important;
}

.ButtonBadgeOK {
  margin-top: -5px !important;
  margin-right: -10px !important;
}

.AlertStyle {
  margin-top: 0.5rem !important;
  font-weight: 300 !important;
}
.info {
  font-family: "Roboto";
  font-weight: 500;
  text-align: left;
}
.infoHiglight {
  color: #01857a;
}
.boxListed {
  list-style: none;
  color: #01807a;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  padding: 1rem;
}
.toolTip {
  color: #33cccc;
  width: 1.3rem;
  margin: 0 0.5rem;
}
@media only screen and (max-width: 600px) {
  .hide {
    display: none;
  }
}
