.HeaderBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .HeaderItem {
    display: flex;
    flex-direction: column;
  }
}

.ListGroup {
  margin-top: 0.8rem;
  .ListItem {
    padding-left: 0;
    padding-right: 0;

    .ListIcon {
      color: #28938a;
    }
  }

  .SubTitle {
    padding-left: 0;
    font-weight: 100;
  }
}

.ListIcon {
  color: #28938a;
}
.hightlight {
  color: #28938a;
  font-weight: bold;
}

.link {
  font-size: 0.8rem;
  color: #28938a;
  text-decoration: underline;
  cursor: pointer;
}

.error {
  color: #d32f2f !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
