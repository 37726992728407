.CountdownWrapper {
  display: flex;
  justify-content: flex-end;
  color: #01857a;
  margin-bottom: 1rem;
}

.CountdownIcon {
  display: inline-block !important;
  margin-right: 0.5rem;
}

.Countdown {
  display: inline-block !important;
  color: #01857a;
}

.TableDetails {
    .Title {
      color: #3232328c;
    }
  
    .Title,
    .Content {
      font-size: 0.8rem;
    }
  }
  