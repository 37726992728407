.DialogDetails {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  padding: 3rem;
  text-align: justify;
  text-justify: inter-word;

  position: relative;

  .DialogContent {
    height: 50vh;
    overflow-y: auto;
    border: 1px solid #e3e3e3;
    padding: 1rem;
  }

  .DialogFooter {
   margin-top: 1rem;
  }

  a {
    color: #01857A !important;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    font-weight: 900;
  }
}
