// $disabled-color: rgba(0, 0, 0, 0.38);
$disabled-color: #f2f2f2;
$colibid-color: #01857a;

.chooseBidBtn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.rowDisabled {
  background-color: #6c636324;
  color: gray;
}

.tableContainer {
  max-height: 400px;
  overflow-x: auto;
}

.customTable {
  display: block;
  width: 100%;
  border-collapse: separate;
  font-weight: bold;
  font-size: small;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  tr {
    th,
    td {
      text-align: center;
      padding: 0.5rem;
      border-bottom: 0.5px solid $disabled-color;
      border-right: 0.5px solid $disabled-color;
    }

    th {
      position: sticky;
      background-color: white;
      z-index: 2;
      top: 0;
      left: 0;

      text-align: center;
      color: $colibid-color;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg.deactive {
          visibility: hidden;
        }
      }
    }

    td {
      @media screen and (min-width: 600px) {
        font-size: calc(10px + (16 - 10) * ((100vw - 600px) / (1600 - 300)));
      }
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }
  }
}

.customPadding {
  padding: 10px;
}

.pulseStart {
  color: gold;
  display: inline-block;
  animation: pulse 2s infinite;
  transform-origin: center center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.highlight {
  color: #01807a;
  font-weight: 500;
}