.Select {
  background-color: #fff !important;

  &:hover {
    background-color: #fff;
  }

  & > *:focus {
    background-color: #fff !important;
  }

  &.Mui-focused {
    background-color: #fff;
  }

  &>* {
    display: flex !important; // i dont want to use important but cant find other way 🥹,
    justify-content: center;
  }

}

.SelectItem {
  text-align: center;
  display: block !important;
}

// .MuiSelect-select::before {
//   display: flex;
//   justify-content: center;
// }

.F {}