.HomeWrapper {
  height: 100vh; /* Establece la altura al 100% del alto de la ventana del navegador */
}
.fullHeight {
  height: 70%;
}

.HomeWrapper {
  .HeaderBox {
    text-align: center;
    margin-top: 1rem;
  }
}
