.CountdownIcon {
  display: inline-block !important;
  margin-top: -3px;
  margin-right: 0.5rem;
}

.Countdown {
  display: inline-block !important;
  font-family: Arial;
  color: #01857a;
}

.CountdownWrapper {
  display: flex;
  justify-content: flex-end;
  color: #01857a;
}
.TableLogo {
  box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.DialogDetails {
  padding: 1.5rem;

  .Title {
    color: #3232328c;
  }

  .Title,
  .Content {
    font-size: 0.8rem;
  }
}

.TableDetails {
  .Title {
    color: #3232328c;
  }

  .Title,
  .Content {
    font-size: 0.8rem;
  }
}

.DebTitle {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin-bottom: 0px !important;
}
div.ButtonsWrapper-sc-__sc-1ckh2u2-2.gQAyhH {
  display: none;
}

.AccordionTitle {
  color: #01857a;
  width: 70%;
  flex-shrink: 0;
  font-size: 0.8rem !important;
}

.AccordionCaption {
  color: #636363;
  font-size: 0.8rem !important;
}

.AccordionIconTitle {
  float: left;
  margin-top: -2px;
}

.AccordionContent {
  font-size: 0.9rem !important;
}

.cardHistory {
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgb(92 103 128 / 15%);
  background-color: #fff;
  margin-top: 1rem;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardTitleHistory {
  font-size: 1.5rem;
  border-bottom: 2px solid #ccc;
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.cardTitleHistory:before {
  position: absolute;
  background: #01857acf;
  height: 2px;
  content: "";
  width: 60%;
  bottom: -2px;
  left: 0;
}
.cardInfoHistory td {
  text-align: initial !important;
}
.cardInfoHistory tbody td:first-child {
  font-weight: bold;
}
.chipInterest {
  margin-left: 4px;
}
