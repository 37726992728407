@mixin for-phone-portrait {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-phone-landscape {
  @media only screen and (max-width: 850px) and (min-width: 450px)  {
    @content;
  }
}
@mixin for-tablet-portrait {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-large-desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
