.DialogDetails {
  padding: 1.5rem;
}

.LabelCheckbox {
  font-size: 0.9rem !important;
  width: 100%;
}

.rightLabel {
  float: right;
  font-size: 0.9rem !important;
}
.hideInputsFilters {
  display: none !important;
}
.filterTooltip {
  width: 1em;
  height: 1em;
  font-size: 0.9rem;
}
.tittleFilter {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .tittleFilter {
    font-size: 1rem !important;
    position: relative;
  }
  .tittleFilter button {
    float: right;
    top: 0;
    bottom: 0.2rem;
  }
}
