@import "./themes/scss/media-queries.scss";

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  background-color: #70707005;
  // height: 100vh;
  // height: -webkit-fill-available;
}

#content {
  // padding-top: 1rem;

  &.not-auth-layout {
    padding-top: 0;
    position: relative;
    // height: calc(98vh - 64px);
    // padding-top: unset;
  }

  &.main-layout {
    height: calc((var(--vh, 1vh) * 100) - 128px);
    overflow-y: auto;
    position: relative;

    &.bidder {
      height: calc((var(--vh, 1vh) * 100) - 64px);
    }

    &.client {
      padding-top: 0;
      // the footer navbar height
      padding-bottom: calc(72px + 1rem);
      // the --vh variable will be set from js taking the window innerHeight * 0.01
    }

  }
}

.phone-selector svg {
  max-width: 2rem;
}

.MuiGrid-root {
  position: relative;
}

.MuiTooltip-tooltip {
  background-color: rgb(0 0 0 / 80%) !important;
  font-weight: 400 !important;
}

.MuiTooltip-arrow {
  color: rgb(0 0 0 / 80%) !important;
}

.containerPinCode {
  display: flex !important;
}

.containerPinCode input[type="text"]:focus {
  outline: none !important;
  box-shadow: 0 0 10px #01857a;
}