.LoginWrapper {
    text-align: center;

   .LoginImage {
       text-align: center;
        margin-bottom: 2rem;
    }
}

.Recovery{ 
    color: #01857A !important;
    cursor: pointer;
}
.Recovery:hover{
    font-weight: 900;
}