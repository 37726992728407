.BetaBadge {
  font-size: 0.6rem !important;
  width: 5rem;
  height: 1.2rem !important;
  color: #969696 !important;
  background-color: #f4f4f4 !important;
  border-radius: 4px !important;
  border: 1px solid #dedbdb !important;
  font-weight: 400;
  margin: 5rem auto;
}

.iconStyle {
  width: 30px;
}
