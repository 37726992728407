.Wrapper {
  margin-top: 1rem;
}
.fullHeight {
  height: 70%;
}
.title {
  font-weight: 700;
  font-size: 20x;
  line-height: 23px;
}

.LoginWrapper {
  text-align: center;

  .LoginImage {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.RecaptchaContainer {
  text-align: center;
  display: inline-block;
}

.links {
  color: #01857a !important;
  cursor: pointer;
}

.links:hover {
  font-weight: 900;
}
.typographic {
  color: #01857a;
}
