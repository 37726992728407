.LoginWrapper {
  text-align: center;
  margin-top: 1rem;
  .LoginImage {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.RecaptchaContainer {
  text-align: center;
  display: inline-block;
}

.links {
  color: #01857a !important;
  cursor: pointer;
}

.links:hover {
  font-weight: 900;
}
