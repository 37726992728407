.iconDownload {
  font-size: 18;
  vertical-align: middle;
}
.buttonDownload {
  width: 42px;
  height: 42px;
  padding: 0;
  min-width: unset !important;
}
