.Title {
  color: #01857a;
}

.Caption {
  color: #636363;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}

.Icon {
  float: left;
  font-size: 3.5rem !important;
  margin-right: 1rem;
}

.AccordionTitle {
  color: #01857a;
  width: 50%;
  flex-shrink: 0;
  font-size: 0.8rem !important;
}

.AccordionCaption {
  color: #636363;
  font-size: 0.8rem !important;
}

.AccordionIconTitle {
  color: #01857a;
  float: left;
  margin-top: -2px;
}

.AccordionContent {
  font-size: 0.9rem !important;
}
