.BidButtonWrapper {
  padding: 0.8rem;
}
.Countdown {
  display: inline-block !important;
  color: #01857a;
}

.WrapperChip {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ChipAuto {
  border-radius: 5px !important;
  padding: 1px 0px !important;
  text-transform: uppercase !important;
  font-size: 0.6rem !important;
  height: 1.1rem !important;
  display: block !important;
  text-align: center;
  margin-top: 0.1rem;
  width: 100%;
}
