.Container {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleMaintain {
  margin: 0px 0px 0.35em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 3rem;
  line-height: 1.167;
  letter-spacing: 0em;
  margin-bottom: 10px;
}

.Status {
  font-size: 10rem;
  font-weight: 400;
  margin: 0;
  line-height: 1;
}

.Message {
  font-weight: 300;
  font-size: 3.25rem;
  margin: 0;
  line-height: 1;
}
.logo {
  width: 15%;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .logo {
    width: 45%;
  }

  .titleMaintain {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
}

 .containerImage {
   display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;

    @media screen and (max-width: 600px) {
        width: 100%;
      }

 }

.ShareTools,
.ProfileBox {
  display: flex;
}

.HomeButton {
  background-color: #01757a !important;

  color: #fff !important;
  margin: 0.3rem !important;
}

.FacebookButton {
  background-color: #3b5998 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.LinkedinButton {
  background-color: #0082d3 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.TwitterButton {
  background-color: #1da1f2 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.YoutubeButton {
  background-color: #FF0000 !important;
  color: #fff !important;
  margin: 0.3rem !important;
}

.InstagramButton {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  color: #fff !important;
  margin: 0.3rem !important;
}