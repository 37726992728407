$slow: #f15a24;
$medium-slow: #fdcd00;
$medium-hight: #a4c626;
$hight: #49af46;

.Wrapper {
}
.fullHeight {
  height: 100%;
}
.title {
  font-weight: 700;
  font-size: 20x;
  line-height: 23px;
}

.LoginWrapper {
  text-align: center;

  .LoginImage {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.RecaptchaContainer {
  text-align: center;
  display: inline-block;
}
.boxList {
  display: flex;
  align-items: center;
  justify-content: center;
}
.listItem {
  list-style: none;
}

.links {
  color: #01857a !important;
  cursor: pointer;
}

.links:hover {
  font-weight: 900;
}
.slow {
  color: $slow;
}
.medium-slow {
  color: $medium-slow;
}
.medium-hight {
  color: $medium-hight;
}
.hight {
  color: $hight;
}
.typoFont {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  list-style: none;
}
.captions {
  font-family: "Roboto";
  font-style: normal;
  font-size: 10px;
  color: #979797;
  text-align: center;
}
