.Box {
  text-align: center;

  padding-bottom: 2rem;

  .HeaderBox {
    margin-bottom: 1.5rem;
  }

  img {
    width: 70%;
    margin: auto;
    margin-bottom: 1.5rem;
  }

  .P1 {
    margin-bottom: 1rem;
    display: block;
    font-size: 0.9rem;
  }

  .P2 {
    margin-bottom: 1rem;
    display: block;
    font-size: 0.9rem;
  }
}

.AccordionDetails {
  &:before {
    background-color: rgb(255 255 255 / 12%) !important;
  }
}
