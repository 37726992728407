.mortgageForm {
  height: 100%;
  position: relative;
}

.StepTwoImage {
  text-align: center;
  // padding: 1rem;
  margin-top: 2rem;
}

.StepThreeImage {
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
}

.StepFourImage {
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
}

.ActionButtons {
  text-align: center;

  button {
    margin: 0.5rem;
  }
}

.SuccessMessageImage {
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
}

.InputLabelTrim {
  writing-mode: horizontal-tb !important;
  inline-size: 320px !important;

  @media only screen and (max-width: 600px) {
    writing-mode: horizontal-tb !important;
    inline-size: 200px !important;
  }
}

.links {
  color: #01857A !important;
  cursor: pointer;
}