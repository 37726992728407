.skeleton_chart {
  display: flex;
  justify-content: center;
  align-items: flex-end;



}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }

    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
}

.skeleton_vbar {
  width: 10px;
  height: 200px;
  margin-right: 10px;
}


.skeleton_bar {
  width: 20px;
}

.skeleton_hbar {
  width: 250px;
  height: 10px;
  margin-top: 10px;
}