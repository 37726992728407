.GridWrapper {
  float: right;
  position: absolute;
  right: -2.5rem;
  top: 43%;
}

.Icon {
  color: #535461;
}

.FieldWrapper {
  position: relative;

  .FieldBubble {
    background-color: #eefcfd;
    position: absolute;
    z-index: 999;
    border: 1px solid #c0dbdd;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 100;
    width: 70%;
    right: 0;
    margin-top: 0.5rem;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      right: 20px;
      width: 0;
      border-top: 7px solid #90c0c3;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      transform: rotate(178deg);
    }
  }
}
